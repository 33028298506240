import React, {useState, useEffect} from 'react';
import {json, useParams} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import ReactDOM from 'react-dom/client'
import parse from 'html-react-parser';

import {
    CreateEbayDescription
} from '../functions/functions';

export default function Preview() {
    const [type, setType] = useState('');
    const [weight, setWeight] = useState('');
    const [shape, setShape] = useState('');
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [depth, setDepth] = useState('');
    const [color, setColor] = useState('');
    const [clarity, setClarity] = useState('');
    const [origin, setOrigin] = useState('');
    const [treatment, setTreatment] = useState('');
    const [certificate, setCertificate] = useState('');
    const [seller, setSeller] = useState('');
    const [images, setImages] = useState('');
    const [videos, setVideos] = useState('');
    const [certimgs, setCertimgs] = useState('');
    const [woo, setWoo] = useState('');
    const [wooid, setWooID] = useState('');
    const [price, setPrice] = useState('');
    const [priceOption, setPriceOption] = useState('sale');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [imageDisplay, setImageDisplay] = useState([]);
    const [videoDisplay, setVideoDisplay] = useState([]);
    const [certDisplay, setCertDisplay] = useState([]);

    const [isUploading, setIsuploading] = useState(false);
    const [status, setStatus] = useState('');
    const [websiteURL, setWebsiteURL] = useState('');
    const [isListToWeb, setListToWeb] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        //alert(id)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/" + id, requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                if (result['status'] === 'ok') {
                    setType(result['gem']['type'])
                    setWeight(result['gem']['weight'])
                    setShape(result['gem']['shape'])
                    setWidth(result['gem']['width'])
                    setLength(result['gem']['length'])
                    setDepth(result['gem']['depth'])
                    setColor(result['gem']['color'])
                    setClarity(result['gem']['clarity'])
                    setOrigin(result['gem']['origin'])
                    setTreatment(result['gem']['treatment'])
                    setCertificate(result['gem']['certificate'])
                    setSeller(result['gem']['seller'])
                    setImages(result['gem']['images'])
                    setVideos(result['gem']['videos'])
                    setCertimgs(result['gem']['certimgs'])
                    setPrice(result['gem']['price'])
                    setPriceOption(result['gem']['priceOption'])
                    setTitle(result['gem']['title'])
                    setDescription(result['gem']['description'])

                    setWoo(result['gem']['woo'])
                    if (typeof result['gem']['woo'] == 'object' & result['gem']['woo'] != null) {
                        setListToWeb(true);
                        setWebsiteURL(result['gem']['woo'].permalink);
                        setWooID(result['gem']['woo'].id)
                    } else if (typeof result['gem']['woo'] == 'undefined') {
                        setListToWeb(false);
                    } else if (result['gem']['woo'] = null) {
                        setListToWeb(false);
                    }

                    setImageDisplay(result['gem']['images'])
                    setVideoDisplay(result['gem']['videos'])
                    setCertDisplay(result['gem']['certimgs'])

                }
            })
            .catch(error => console.log('error', error));

    }, [id])

    const gem = {
        "id": id,
        "seller": seller,
        "type": type,
        "weight": weight,
        "shape": shape,
        "width": width,
        "length": length,
        "depth": depth,
        "color": color,
        "clarity": clarity,
        "origin": origin,
        "treatment": treatment,
        "certificate": certificate,
        "images": images,
        "videos": videos,
        "certimgs": certimgs,
        "price": price,
        "priceOption": priceOption,
        "woo": woo,
        "title":title,
        "description":description

    };

    const createHTML = async () => {
        const container = document.getElementById('preview');
        const root = ReactDOM.createRoot(container);
        const htmlstring = await parse(CreateEbayDescription(gem,false));
        root.render(htmlstring);
         ///console.log(CreateEbayDescription(gem,true));
    }

    createHTML();

    return (
        <React.Fragment>
            <CssBaseline/>
            <div id="preview"></div>
        </React.Fragment>
    );
}