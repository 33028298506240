

export const sellerLists = [
  {"key":"SSS",
   "value":"TON"
  },
  {"key":"TNK",
   "value":"Dodo"
  },
  {"key":"SMK",
   "value":"Somsak"
  },
  {"key":"BTT",
   "value":"Banthita"
  },
  {"key":"KTK",
   "value":"Krittika"
  },
  {"key":"GPB",
   "value":"Good Paraiba"
  },
  {"key":"NOT",
   "value":"Note"
  },
  {"key":"PIN",
   "value":"Ping"
  },
  {"key":"KKK",
   "value":"KGE"
  }



];

export const gemtypeLists = [
  {"key":"gemstone",
   "value":"Gemstone",
   "woo_id":75
  },
  {"key":"paraiba",
   "value":"Paraiba Tourmaline",
   "woo_id":93
  },
  {"key":"rubellite",
   "value":"Rubellite Tourmaline",
   "woo_id":116
  },
  {"key":"spessartite",
   "value":"Spessartite Garnet",
   "woo_id":117
  },
  {"key":"copper-tourmaline",
   "value":"Copper Bearing Tourmaline",
   "woo_id":119
  },
  {"key":"aquamarine",
   "value":"Aquamarine",
   "woo_id":118
  },
  {"key":"ruby",
   "value":"Ruby",
   "woo_id":123
  },
  {"key":"sphene",
   "value":"Sphene",
   "woo_id":75
  }
  ,
  {"key":"sapphire",
   "value":"Sapphire",
   "woo_id":124
  },
  {"key":"emerald",
   "value":"Emerald",
   "woo_id":125
  },
  {"key":"spinel",
   "value":"Spinel",  
   "woo_id":126
  },
  {"key":"stavolite",
   "value":"Stavolite Garnet",
   "woo_id":127
  },
  {"key":"opal",
   "value":"Opal",
   "woo_id":128
  },
  {"key":"tourmaline",
   "value":"Tourmaline",
   "woo_id":140
  },
  {"key":"ametrine",
   "value":"Ametrine",
   "woo_id":233
  },
  {"key":"zoisite",
   "value":"Zoisite",
   "woo_id":245
  },
  {"key":"amethyst",
   "value":"Amethyst",
   "woo_id":256
  }
  
  
  
  

];

export const shapeLists = [
  {"key":"oval",
   "value":"Oval"
  },
  {"key":"cushion",
   "value":"Cushion"
  },
  {"key":"octagon",
   "value":"Octagon"
  },
  {"key":"round",
   "value":"Round"
  },
  {"key":"emerald",
   "value":"Emerald"
  },
  {"key":"heart",
   "value":"Heart"
  },
  {"key":"pear",
   "value":"Pear"
  },
  {"key":"trillion",
   "value":"Trillion"
  },
  {"key":"hexagon",
   "value":"Hexagon"
  },
  {"key":"cabochon-oval",
   "value":"Cabochon Oval"
  },
  {"key":"cabochon",
   "value":"Cabochon"
  },
  {"key":"rose-carving",
   "value":"Rose Carving"
  }

];

export const colorLists = [
  {"key":"blue",
   "value":"Blue"
  },
  {"key":"neon-blue",
   "value":"Neon Blue"
  },
  {"key":"red",
   "value":"Red"
  },
  {"key":"pegeonblood",
   "value":"Pegeon Blood"
  },
  {"key":"pink",
   "value":"Pink"
  },
  {"key":"green",
   "value":"Green"
  },
  {"key":"greenish-blue",
   "value":"Greenish Blue"
  },
  {"key":"bluish-green",
   "value":"Bluish Green"
  },
  {"key":"yellowish-green",
   "value":"Yellowish Green"
  },
  {"key":"yellowish-pink",
   "value":"Yellowish Pink"
  },
  {"key":"yellowish",
   "value":"Yellowish"
  },
  {"key":"white",
   "value":"White"
  },
  {"key":"play-of-color",
   "value":"Play Of Color"
  },
  {"key":"flash-play-of-color",
   "value":"Flash Play-of-Color"
  },
  {"key":"green-red",
   "value":"Green & Red"
  },
  {"key":"purple",
   "value":"Purple"
  },
  {"key":"orange",
   "value":"Orange"
  }


];

export const clarityLists = [
  {"key":"loup-clean",
   "value":"Loup Clean"
  },
  {"key":"eye-clean",
   "value":"Eye Clean"
  },
  {"key":"slightly-included",
   "value":"Slightly included"
  },
  {"key":"moderatly-included",
   "value":"Moderatly Included"
  },
  {"key":"heavily-included",
   "value":"Heavily Included"
  },
  {"key":"severely-included",
   "value":"Severely Included"
  },
  {"key":"opaque",
   "value":"Opaque"
  },
  {"key":"semi-transparent",
   "value":"Semi Transparent"
  }


];

export const originLists = [
  {"key":"mozambique",
   "value":"Mozambique"
  },
  {"key":"nigeria",
   "value":"Nigeria"
  },
  {"key":"brazil",
   "value":"Brazil"
  },
  {"key":"thailand",
   "value":"Thailand"
  },
  {"key":"burma",
   "value":"Burma"
  },
  {"key":"madagasca",
   "value":"Madagasca"
  },
  {"key":"queensland-australia",
   "value":"Queensland, Australia"
  },
  {"key":"africa",
   "value":"Africa"
  }

];

export const treatmentLists = [
  {"key":"not-enhanced",
   "value":"Not Enhanced"
  },
  {"key":"no-treatment",
   "value":"No Treatment"
  },
  {"key":"heat",
   "value":"Heat"
  },
  {"key":"no-heat",
   "value":"No Heat"
  },
  {"key":"heat-glass-filling",
   "value":"Heat Glass Filling"
  },
  {"key":"unheated-untreated",
   "value":"Unheated Untreated"
  }

];

export const certs = [
  'Not Include',
  'GLC',
  'BGL',
  'GIT',
  'GIA',
  'GRS',
  'AIGS',
  'AIG'

];

export const handleUpdate = async (raw) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };
  await fetch("http://localhost:2005/gems/update/", requestOptions)
      .then(
          response => response.json()
      )
      .then(result => {console.log(result);return result})
      .catch(error => console.log('error', error));
      

}