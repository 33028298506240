//import * as React from 'react';
import React, {useState,useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import Container from '@mui/material/Container';

export default function VisitorLog() {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        VisitorLogGet()
      }, [])

    const VisitorLogGet = () =>{
        // Fetch the visitor logs from the server
        fetch('https://app.gemnoble.com:2005/logs')
        .then(response => response.json())
        .then(data => setLogs(data))
        .catch(error => console.error('Error fetching logs:', error));
          
    }
    


  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{p:2}}>

      <div>
      <h2>Visitor Logs</h2>
      <table>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Product ID</th>
            <th>IP Address</th>
            <th>User Agent</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => (
            <tr key={index}>
              <td>{log.timestamp}</td>
              <td>{log.productId}</td>
              <td>{log.ip}</td>
              <td>{log.userAgent}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    
      </Container>
    </React.Fragment>
  );
}  