import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import ButtonGroup from '@mui/material/ButtonGroup';
import Pagination from '@mui/material/Pagination'; // Import Pagination component
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { deleteMedia } from '../functions/wordpressAPI';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { gemtypeLists, shapeLists, colorLists, clarityLists, originLists, treatmentLists } from '../utils/Utils';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import FilterListIcon from '@mui/icons-material/FilterList';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function Gems() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoaded, setIsLoaded] = useState(true);
    const [user, setUser] = useState([]);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    
    // Filter states
    const [typeFilter, setTypeFilter] = useState('');
    const [shapeFilter, setShapeFilter] = useState('');
    const [colorFilter, setColorFilter] = useState('');
    const [clarityFilter, setClarityFilter] = useState('');
    const [originFilter, setOriginFilter] = useState('');
    const [treatmentFilter, setTreatmentFilter] = useState('');

    // Pagination state
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [rowsPerPageOptions] = useState([5, 10, 25, 50, 100]);

    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        filterItems();
    }, [items, typeFilter, shapeFilter, colorFilter, clarityFilter, originFilter, treatmentFilter]);

    const filterItems = () => {
        let filtered = [...items];
        
        if (typeFilter) {
            filtered = filtered.filter(item => item.type === typeFilter);
        }
        if (shapeFilter) {
            filtered = filtered.filter(item => item.shape === shapeFilter);
        }
        if (colorFilter) {
            filtered = filtered.filter(item => item.color === colorFilter);
        }
        if (clarityFilter) {
            filtered = filtered.filter(item => item.clarity === clarityFilter);
        }
        if (originFilter) {
            filtered = filtered.filter(item => item.origin === originFilter);
        }
        if (treatmentFilter) {
            filtered = filtered.filter(item => item.treatment === treatmentFilter);
        }

        setFilteredItems(filtered);
        setPage(1); // Reset to first page when filters change
    };

    const gemGet = () => {
        fetch("https://app.gemnoble.com:2005/gems")
          .then(res => res.json())
          .then(
            (result) => {
              setItems(result);
              setFilteredItems(result);
              setIsLoaded(false);
            }
          );
    }

    const checkAuth = () => {
      const isAuthenticated = localStorage.getItem('isAuthenticated');
      
      if (isAuthenticated === 'true') {
            gemGet();
          } else {
            Swal.fire({
              icon: "error",
          text: "Please login first"
            }).then(() => {
              navigate('/signin');
            });
          }
    }

    const handleDeleteImages = async (images) => {
        for (let i = 0; i < images.length; i++) {
            await deleteMedia(images[i].id);
        }
        console.log("Deleted images");
    };

    const handleDeleteVideos = async (videos) => {
        for (let i = 0; i < videos.length; i++) {
            await deleteMedia(videos[i].id);
        }
        console.log("Deleted videos");
    };

    const handleDeleteCerts = async (certs) => {
        for (let i = 0; i < certs.length; i++) {
            await deleteMedia(certs[i].id);
        }
        console.log("Deleted certimgs");
    };

    const UserDelete = (gem) => {
      if (gem.woo == null) {
        handleDeleteImages(gem.images);
        handleDeleteVideos(gem.videos);
        handleDeleteCerts(gem.certimgs);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({ "id": gem.id });
        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        fetch("https://app.gemnoble.com:2005/gems/delete/", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result['status'] === 'ok')
              checkAuth();
          })
          .catch(error => console.log('error', error));
      } else if (gem.woo != null) {
        Swal.fire({
          icon: "error",
          text: "Unable to Delete Because it is now Live on Website Link: " + gem.woo.permalink
        });
      }
    }

    // Pagination logic
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when changing rows per page
    };

    const paginatedItems = filteredItems.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    // Filter drawer handlers
    const toggleFilterDrawer = () => {
        setIsFilterDrawerOpen(!isFilterDrawerOpen);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ p: isMobile ? 1 : 2 }}>
                <Paper sx={{ p: isMobile ? 1 : 2 }}>
                    <Box display="flex" sx={{ mb: 2 }} alignItems="center">
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" gutterBottom={!isMobile}>
                                Gems
                            </Typography>
                        </Box>
                        
                        {isMobile && (
                            <Button
                                startIcon={<FilterListIcon />}
                                onClick={toggleFilterDrawer}
                                sx={{ mr: 1 }}
                                variant="outlined"
                            >
                                Filters
                            </Button>
                        )}
                    
                        <Box>
                            <Link href="create" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" size={isMobile ? "small" : "medium"}>
                                    Create
                                </Button>
                            </Link>
                        </Box>
                    </Box>

                    {/* Desktop Filters */}
                    {!isMobile && (
                        <Grid container spacing={2} sx={{ mb: 3 }}>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        value={typeFilter}
                                        label="Type"
                                        onChange={(e) => setTypeFilter(e.target.value)}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {gemtypeLists.map((type) => (
                                            <MenuItem key={type.key} value={type.key}>
                                                {type.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Shape</InputLabel>
                                    <Select
                                        value={shapeFilter}
                                        label="Shape"
                                        onChange={(e) => setShapeFilter(e.target.value)}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {shapeLists.map((shape) => (
                                            <MenuItem key={shape.key} value={shape.key}>
                                                {shape.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Color</InputLabel>
                                    <Select
                                        value={colorFilter}
                                        label="Color"
                                        onChange={(e) => setColorFilter(e.target.value)}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {colorLists.map((color) => (
                                            <MenuItem key={color.key} value={color.key}>
                                                {color.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Clarity</InputLabel>
                                    <Select
                                        value={clarityFilter}
                                        label="Clarity"
                                        onChange={(e) => setClarityFilter(e.target.value)}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {clarityLists.map((clarity) => (
                                            <MenuItem key={clarity.key} value={clarity.key}>
                                                {clarity.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Origin</InputLabel>
                                    <Select
                                        value={originFilter}
                                        label="Origin"
                                        onChange={(e) => setOriginFilter(e.target.value)}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {originLists.map((origin) => (
                                            <MenuItem key={origin.key} value={origin.key}>
                                                {origin.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Treatment</InputLabel>
                                    <Select
                                        value={treatmentFilter}
                                        label="Treatment"
                                        onChange={(e) => setTreatmentFilter(e.target.value)}
                                    >
                                        <MenuItem value="">All</MenuItem>
                                        {treatmentLists.map((treatment) => (
                                            <MenuItem key={treatment.key} value={treatment.key}>
                                                {treatment.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}

                    {/* Mobile Filter Drawer */}
                    <Drawer
                        anchor="right"
                        open={isFilterDrawerOpen}
                        onClose={toggleFilterDrawer}
                        PaperProps={{
                            sx: { width: '80%', maxWidth: '300px', p: 2 }
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6">Filters</Typography>
                            <IconButton onClick={toggleFilterDrawer}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Divider sx={{ mb: 2 }} />
                        <Stack spacing={2}>
                            <FormControl fullWidth size="small">
                                <InputLabel>Type</InputLabel>
                                <Select
                                    value={typeFilter}
                                    label="Type"
                                    onChange={(e) => setTypeFilter(e.target.value)}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {gemtypeLists.map((type) => (
                                        <MenuItem key={type.key} value={type.key}>
                                            {type.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <InputLabel>Shape</InputLabel>
                                <Select
                                    value={shapeFilter}
                                    label="Shape"
                                    onChange={(e) => setShapeFilter(e.target.value)}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {shapeLists.map((shape) => (
                                        <MenuItem key={shape.key} value={shape.key}>
                                            {shape.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <InputLabel>Color</InputLabel>
                                <Select
                                    value={colorFilter}
                                    label="Color"
                                    onChange={(e) => setColorFilter(e.target.value)}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {colorLists.map((color) => (
                                        <MenuItem key={color.key} value={color.key}>
                                            {color.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <InputLabel>Clarity</InputLabel>
                                <Select
                                    value={clarityFilter}
                                    label="Clarity"
                                    onChange={(e) => setClarityFilter(e.target.value)}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {clarityLists.map((clarity) => (
                                        <MenuItem key={clarity.key} value={clarity.key}>
                                            {clarity.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <InputLabel>Origin</InputLabel>
                                <Select
                                    value={originFilter}
                                    label="Origin"
                                    onChange={(e) => setOriginFilter(e.target.value)}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {originLists.map((origin) => (
                                        <MenuItem key={origin.key} value={origin.key}>
                                            {origin.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <InputLabel>Treatment</InputLabel>
                                <Select
                                    value={treatmentFilter}
                                    label="Treatment"
                                    onChange={(e) => setTreatmentFilter(e.target.value)}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    {treatmentLists.map((treatment) => (
                                        <MenuItem key={treatment.key} value={treatment.key}>
                                            {treatment.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Drawer>

                    {/* Mobile Card View / Desktop Table View */}
                    {isMobile ? (
                        <Stack spacing={2}>
                            {paginatedItems.map((row) => (
                                <Card key={row.id}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Avatar 
                                                alt={row.username} 
                                                src={row.images[0].media_details.sizes.thumbnail.source_url}
                                                sx={{ width: 60, height: 60, mr: 2 }}
                                            />
                                            <Box>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    SKU: {row.id}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {row.type} - {row.weight} ct
                                                </Typography>
                                            </Box>
                                        </Box>
                                        
                                        <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mb: 2 }}>
                                            <Chip label={`Shape: ${row.shape}`} size="small" />
                                            <Chip label={`${row.width} x ${row.length} x ${row.depth}`} size="small" />
                                            <Chip label={`Cert: ${row.certificate}`} size="small" />
                                        </Stack>

                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <Tooltip title="View Listing">
                                                <IconButton 
                                                    onClick={() => window.location = '/listing/' + row.id}
                                                    size="small"
                                                    sx={{
                                                        color: 'info.main',
                                                        '&:hover': {
                                                            backgroundColor: 'info.light',
                                                            color: 'white',
                                                        },
                                                    }}
                                                >
                                                    <ListAltIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Preview Description">
                                                <IconButton 
                                                    onClick={() => window.location = '/preview/' + row.id}
                                                    size="small"
                                                    sx={{
                                                        color: 'success.main',
                                                        '&:hover': {
                                                            backgroundColor: 'success.light',
                                                            color: 'white',
                                                        },
                                                    }}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <IconButton 
                                                    onClick={() => window.location = '/update/' + row.id}
                                                    size="small"
                                                    sx={{
                                                        color: 'primary.main',
                                                        '&:hover': {
                                                            backgroundColor: 'primary.light',
                                                            color: 'white',
                                                        },
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={row.woo === null ? "Delete" : "Cannot delete - Item is live"}>
                                                <span>
                                                    <IconButton 
                                                        onClick={() => UserDelete(row)}
                                                        disabled={!(row.woo === null)}
                                                        size="small"
                                                        sx={{
                                                            color: 'error.main',
                                                            '&:hover': {
                                                                backgroundColor: 'error.light',
                                                                color: 'white',
                                                            },
                                                            '&.Mui-disabled': {
                                                                color: 'action.disabled',
                                                                backgroundColor: 'action.disabledBackground',
                                                            },
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            ))}
                        </Stack>
                    ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>SKU</TableCell>
                                    <TableCell align="center">Avatar</TableCell>
                                    <TableCell align="right">Type</TableCell>
                                    <TableCell align="right">CTW</TableCell>
                                    <TableCell align="right">Shape</TableCell>
                                    <TableCell align="right">Dimension</TableCell>
                                    <TableCell align="right">Cert</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedItems.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box display="flex" justifyContent="center">
                                                <Avatar alt={row.username} src={row.images[0].media_details.sizes.thumbnail.source_url} />
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">{row.type}</TableCell>
                                        <TableCell align="right">{row.weight}</TableCell>
                                        <TableCell align="right">{row.shape}</TableCell>
                                        <TableCell align="right">{row.width + " x " + row.length + " x " + row.depth}</TableCell>
                                        <TableCell align="right">{row.certificate.toString()}</TableCell>
                                            <TableCell align="center">
                                                <Stack direction="row" spacing={1} justifyContent="center">
                                                    <Tooltip title="View Listing">
                                                        <IconButton 
                                                            onClick={() => window.location = '/listing/' + row.id}
                                                            size="small"
                                                            sx={{
                                                                color: 'info.main',
                                                                '&:hover': {
                                                                    backgroundColor: 'info.light',
                                                                    color: 'white',
                                                                },
                                                            }}
                                                        >
                                                            <ListAltIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Preview Description">
                                                        <IconButton 
                                                            onClick={() => window.location = '/preview/' + row.id}
                                                            size="small"
                                                            sx={{
                                                                color: 'success.main',
                                                                '&:hover': {
                                                                    backgroundColor: 'success.light',
                                                                    color: 'white',
                                                                },
                                                            }}
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Edit">
                                                        <IconButton 
                                                            onClick={() => window.location = '/update/' + row.id}
                                                            size="small"
                                                            sx={{
                                                                color: 'primary.main',
                                                                '&:hover': {
                                                                    backgroundColor: 'primary.light',
                                                                    color: 'white',
                                                                },
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={row.woo === null ? "Delete" : "Cannot delete - Item is live"}>
                                                        <span>
                                                            <IconButton 
                                                                onClick={() => UserDelete(row)}
                                                                disabled={!(row.woo === null)}
                                                                size="small"
                                                                sx={{
                                                                    color: 'error.main',
                                                                    '&:hover': {
                                                                        backgroundColor: 'error.light',
                                                                        color: 'white',
                                                                    },
                                                                    '&.Mui-disabled': {
                                                                        color: 'action.disabled',
                                                                        backgroundColor: 'action.disabledBackground',
                                                                    },
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    )}
                    
                    <Box 
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center" 
                        marginTop={2}
                        flexDirection={isMobile ? "column" : "row"}
                        gap={2}
                    >
                        <FormControl size="small" sx={{ minWidth: 120 }}>
                            <Select
                                value={rowsPerPage}
                                onChange={handleChangeRowsPerPage}
                                displayEmpty
                                variant="outlined"
                            >
                                {rowsPerPageOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option} items per page
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Pagination
                            count={Math.ceil(filteredItems.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                            size={isMobile ? "small" : "medium"}
                        />
                    </Box>
                </Paper>
            </Container>
        </React.Fragment>
    );
}
