import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom'
import createRoot from 'react-dom'
import parse from 'html-react-parser';
import {json, useParams} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Button, Grid, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import LinearProgress from '@mui/material/LinearProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import axios from 'axios';
import xml2js from 'xml2js';
import {
    sellerLists,
    gemtypeLists,
    shapeLists,
    colorLists,
    clarityLists,
    originLists,
    treatmentLists,
    certs
} from '../utils/Utils';
import {CreateTitle,CreateParagrahp,CreateWooDescription,createXmlEbay,PriceOption,CreateEbayDescription} from '../functions/functions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid2 from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import Link from '@mui/material/Link';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ImageIcon from '@mui/icons-material/Image';



export default function GemsListing() {
    const [type, setType] = useState('');
    const [weight, setWeight] = useState('');
    const [shape, setShape] = useState('');
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [depth, setDepth] = useState('');
    const [color, setColor] = useState('');
    const [clarity, setClarity] = useState('');
    const [origin, setOrigin] = useState('');
    const [treatment, setTreatment] = useState('');
    const [certificate, setCertificate] = useState('');
    const [seller, setSeller] = useState('');
    const [images, setImages] = useState('');
    const [videos, setVideos] = useState('');
    const [certimgs, setCertimgs] = useState('');
    const [woo, setWoo] = useState('');
    const [wooid, setWooID] = useState('');
    const [price, setPrice] = useState('');
    const [priceOption, setPriceOption] = useState('sale');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');


    const [imageDisplay, setImageDisplay] = useState([]);
    const [videoDisplay, setVideoDisplay] = useState([]);
    const [certDisplay, setCertDisplay] = useState([]);

    const [isUploading,setIsuploading] = useState(false);
    const [status,setStatus] = useState('');
    const [websiteURL,setWebsiteURL] = useState('');
    const [isListToWeb,setListToWeb] = useState(false);

    const [checked, setChecked] = useState(false);
    
    const [accesstoken, seAccesstoken] = useState("");
    const [accesstoken_isValid, setAccesstoken_isValid] = useState("");
    const [accesstoken_expirein, seAccesstoken_expirein] = useState("");
    const [data, setData] = useState(null);


    const {id} = useParams();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedImage, setSelectedImage] = useState(null);
    const [openImageDialog, setOpenImageDialog] = useState(false);

    useEffect(() => {
        //alert(id)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/" + id, requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                if (result['status'] === 'ok') {

                    //setId(result['gem']['id'])
                    setType(result['gem']['type'])
                    setWeight(result['gem']['weight'])
                    setShape(result['gem']['shape'])
                    setWidth(result['gem']['width'])
                    setLength(result['gem']['length'])
                    setDepth(result['gem']['depth'])
                    setColor(result['gem']['color'])
                    setClarity(result['gem']['clarity'])
                    setOrigin(result['gem']['origin'])
                    setTreatment(result['gem']['treatment'])
                    setCertificate(result['gem']['certificate'])
                    setSeller(result['gem']['seller'])
                    setImages(result['gem']['images'])
                    setVideos(result['gem']['videos'])
                    setCertimgs(result['gem']['certimgs'])
                    setPrice(result['gem']['price'])
                    setPriceOption(result['gem']['priceOption'])
                    setTitle(result['gem']['title'])
                    setDescription(result['gem']['description'])

                    setWoo(result['gem']['woo'])
                    if (typeof result['gem']['woo'] == 'object' & result['gem']['woo'] != null){
                        //console.log(result['gem']['woo']);
                        setListToWeb(true);
                        setWebsiteURL(result['gem']['woo'].permalink);
                        setWooID(result['gem']['woo'].id)

                    } else if(typeof result['gem']['woo'] == 'undefined'){
                        setListToWeb(false);
                    }else if(result['gem']['woo'] = null){
                        setListToWeb(false);
                    }
                    
                    setImageDisplay(result['gem']['images'])
                    setVideoDisplay(result['gem']['videos'])
                    setCertDisplay(result['gem']['certimgs'])

                if (typeof result['gem']['woo'] == 'object' & result['gem']['woo'] != null){
                    //console.log(result['gem']['woo']['status']);
                    if(result['gem']['woo']['status']=="private"){
                        setChecked(false);

                    }else if(result['gem']['woo']['status']=="publish"){
                        setChecked(true);
                    }
                }

                }
            })
            .catch(error => console.log('error', error));

            getAccessToken()


    }, [id])

    const getAccessToken = () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
          
          fetch("https://app.gemnoble.com:2005/auth/ebay/accesstoken", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                //console.log(result.token);
                seAccesstoken(result.token);
                setAccesstoken_isValid(result.isValid);
                seAccesstoken_expirein(result.expire_in);
                const interval = setInterval(() => {
                    seAccesstoken_expirein((accesstoken_expirein) => accesstoken_expirein - 1);
                  }, 1000);
              
                  return () => clearInterval(interval);
            })
            .catch((error) => console.error(error));
    }



    const  gem = {
        "id":id,
        "seller": seller,
        "type": type,
        "weight": weight,
        "shape": shape,
        "width": width,
        "length": length,
        "depth": depth,
        "color": color,
        "clarity": clarity,
        "origin": origin,
        "treatment": treatment,
        "certificate": certificate,
        "images": images,
        "videos": videos,
        "certimgs": certimgs,
        "price":price,
        "priceOption":priceOption,
        "woo":woo,
        "title":title,
        "description":description
        
    };

        const listToWeb =  (gem) =>{
        return new Promise(async (resolve,reject)=>{
            var woo = {
                "name":gem.title,
                "slug":gem.id,
                "sku":gem.id,
                "type": "simple",
                "status": "private",
                "sold_individually": true,
                "description":CreateWooDescription(gem),
                "regular_price": gem.price,
                "categories": [
                    {
                        "id": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].woo_id
                    }
                ],
                "images":[
                    ...images,
                    ...certimgs
                ],
                "attributes": [
                    {
                        "id":4,
                        "position": 0,
                        "name": "Gemstone Type",
                        "visible": true,
                        "variation": true,
                        "options": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].value,
                    },
                    {
                        "id": 10,
                        "name": "Carat",
                        "position": 1,
                        "visible": true,
                        "variation": true,
                        "options": gem.weight
    
                    },
                    {
                        "id": 5,
                        "name": "Shape",
                        "position": 2,
                        "visible": true,
                        "variation": true,
                        "options": shapeLists[shapeLists.findIndex(shapeLists => shapeLists.key === gem.shape)].value
                    },
                    {
                        "id": 1,
                        "name": "Color",
                        "position": 3,
                        "visible": true,
                        "variation": true,
                        "options": colorLists[colorLists.findIndex(colorLists => colorLists.key === gem.color)].value
                    },
                    {
                        "id": 9,
                        "name": "Clarity Grade",
                        "position": 4,
                        "visible": true,
                        "variation": true,
                        "options": clarityLists[clarityLists.findIndex(clarityLists => clarityLists.key === gem.clarity)].value
                    },
                    {
                        "id": 6,
                        "name": "Origin",
                        "position": 5,
                        "visible": true,
                        "variation": true,
                        "options": originLists[originLists.findIndex(originLists => originLists.key === gem.origin)].value
                    },
                    {
                        "id": 7,
                        "name": "Treatment",
                        "position": 6,
                        "visible": true,
                        "variation": true,
                        "options": treatmentLists[treatmentLists.findIndex(treatmentLists => treatmentLists.key === gem.treatment)].value
                    },
                    {
                        "id": 8,
                        "name": "Certification",
                        "position": 7,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    },
                    {
                        "id": 2,
                        "name": "Brand",
                        "position": 8,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    }
                ],
                "meta_data": [
                    /*
                    {
                    "id": 15636,
                    "key": "offers_for_woocommerce_enabled",
                    "value": "yes"
                    },
                    */
                
                {
                    "id": 23671,
                    "key": "_woodmart_product_video",
                    "value": videos[0].source_url
                }
            ],
            };
            var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
            
                var gemraw = JSON.stringify(woo);
                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: gemraw,
                redirect: 'follow'
                };
                await fetch("https://gemnoble.com/wp-json/wc/v3/products/", requestOptions)
                .then(response => response.text())
                .then(result => {
                    resolve(JSON.parse(result));
                    })
                .catch(error => reject(error));
            


        })
    }

    const updateToWeb =  (gem,id) =>{
        return new Promise(async (resolve,reject)=>{
            var woo = {
                "name":gem.title,
                "slug":gem.id,
                "sku":gem.id,
                "type": "simple",
                "status": checked?("publish"):("private"),
                "sold_individually": true,
                "description":CreateWooDescription(gem),
                "regular_price": gem.price,
                "categories": [
                    {
                        "id": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].woo_id
                    }
                ],
                "images":[
                    ...images,
                    ...certimgs
                ],
                "attributes": [
                    {
                        "id":4,
                        "position": 0,
                        "name": "Gemstone Type",
                        "visible": true,
                        "variation": true,
                        "options": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].value,
                    },
                    {
                        "id": 10,
                        "name": "Carat",
                        "position": 1,
                        "visible": true,
                        "variation": true,
                        "options": gem.weight
    
                    },
                    {
                        "id": 5,
                        "name": "Shape",
                        "position": 2,
                        "visible": true,
                        "variation": true,
                        "options": shapeLists[shapeLists.findIndex(shapeLists => shapeLists.key === gem.shape)].value
                    },
                    {
                        "id": 1,
                        "name": "Color",
                        "position": 3,
                        "visible": true,
                        "variation": true,
                        "options": colorLists[colorLists.findIndex(colorLists => colorLists.key === gem.color)].value
                    },
                    {
                        "id": 9,
                        "name": "Clarity Grade",
                        "position": 4,
                        "visible": true,
                        "variation": true,
                        "options": clarityLists[clarityLists.findIndex(clarityLists => clarityLists.key === gem.clarity)].value
                    },
                    {
                        "id": 6,
                        "name": "Origin",
                        "position": 5,
                        "visible": true,
                        "variation": true,
                        "options": originLists[originLists.findIndex(originLists => originLists.key === gem.origin)].value
                    },
                    {
                        "id": 7,
                        "name": "Treatment",
                        "position": 6,
                        "visible": true,
                        "variation": true,
                        "options": treatmentLists[treatmentLists.findIndex(treatmentLists => treatmentLists.key === gem.treatment)].value
                    },
                    {
                        "id": 8,
                        "name": "Certification",
                        "position": 7,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    },
                    {
                        "id": 2,
                        "name": "Brand",
                        "position": 8,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    }
                ],
                "meta_data": [

                {
                    "id": 23671,
                    "key": "_woodmart_product_video",
                    "value": videos[0].source_url
                }
            ],
            };
            var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
            
                var gemraw = JSON.stringify(woo);
                var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: gemraw,
                redirect: 'follow',
                };
                await fetch("https://gemnoble.com/wp-json/wc/v3/products/"+id, requestOptions)
                .then(response => response.text())
                .then(result => {
                        /*
                        if(JSON.parse(result).data.status = 400){
                            reject(JSON.parse(result).message)
                        }else {
                        resolve(JSON.parse(result));
                        }*/
                    
                    resolve(JSON.parse(result));
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                }
                    );
            


        })
    }

    const updateVisible =  (gem,id,visivlestatus) =>{
        return new Promise(async (resolve,reject)=>{
            var woo = {
                "name":gem.title,
                "slug":gem.id,
                "sku":gem.id,
                "type": "simple",
                "status": visivlestatus,
                "sold_individually": true,
                "description":CreateWooDescription(gem),
                "regular_price": gem.price,
                "categories": [
                    {
                        "id": gemtypeLists[gemtypeLists.findIndex(gemtypeLists => gemtypeLists.key === gem.type)].woo_id
                    }
                ],
                "images":[
                    ...images,
                    ...certimgs
                ],
                "attributes": [
                    {
                        "id":4,
                        "position": 0,
                        "name": "Gemstone Type",
                        "visible": true,
                        "variation": true,
                        "options": gem.type,
                    },
                    {
                        "id": 10,
                        "name": "Carat",
                        "position": 1,
                        "visible": true,
                        "variation": true,
                        "options": gem.weight
    
                    },
                    {
                        "id": 5,
                        "name": "Shape",
                        "position": 2,
                        "visible": true,
                        "variation": true,
                        "options": gem.shape
                    },
                    {
                        "id": 1,
                        "name": "Color",
                        "position": 3,
                        "visible": true,
                        "variation": true,
                        "options": gem.color
                    },
                    {
                        "id": 9,
                        "name": "Clarity Grade",
                        "position": 4,
                        "visible": true,
                        "variation": true,
                        "options": gem.clarity
                    },
                    {
                        "id": 6,
                        "name": "Origin",
                        "position": 5,
                        "visible": true,
                        "variation": true,
                        "options": gem.origin
                    },
                    {
                        "id": 7,
                        "name": "Treatment",
                        "position": 6,
                        "visible": true,
                        "variation": true,
                        "options": gem.treatment
                    },
                    {
                        "id": 8,
                        "name": "Certification",
                        "position": 7,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    },
                    {
                        "id": 2,
                        "name": "Brand",
                        "position": 8,
                        "visible": true,
                        "variation": true,
                        "options": gem.certificate
                    }
                ],
                "meta_data": [

                {
                    "id": 23671,
                    "key": "_woodmart_product_video",
                    "value": videos[0].source_url
                }
            ],
            };
            var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
            
                var gemraw = JSON.stringify(woo);
                var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: gemraw,
                redirect: 'follow',
                };
                await fetch("https://gemnoble.com/wp-json/wc/v3/products/"+id, requestOptions)
                .then(response => response.text())
                .then(result => {
                        /*
                        if(JSON.parse(result).data.status = 400){
                            reject(JSON.parse(result).message)
                        }else {
                        resolve(JSON.parse(result));
                        }*/
                    
                    resolve(JSON.parse(result));
                })
                .catch(error => {
                    reject(error);
                    console.log(error);
                }
                    );
            


        })
    }



    const handleConsolelog = e => {
        console.log(gem);
        //console.log(PriceOption(gem.priceOption));
    }



    const handleUpdateWoo = (woo) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
        var raw = JSON.stringify({
            "id": id,
            "type": type,
            "weight": weight,
            "shape": shape,
            "width": width,
            "length": length,
            "depth": depth,
            "color": color,
            "clarity": clarity,
            "origin": origin,
            "treatment": treatment,
            "certificate": certificate,
            "seller": seller,
            "images": images,
            "videos": videos,
            "certimgs": certimgs,
            "price":price,
            "priceOption":priceOption,
            "woo":woo,
            "title":title,
            "description":description
        });
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                console.log(result)
            })
            .catch(error => console.log('error', error));

    }

    const daleteToWeb = (id) => {
        return new Promise(async (resolve,reject)=>{
        var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                //myHeaders.append("Access-Control-Allow-Origin", "No");
                myHeaders.append("Authorization", "Basic Y2tfMTc3NjU5ZmFmYmRhM2I4MzAzYThlZDNkN2VhYmMzYjY4ZjlhYTc0OTpjc19kYmFiNTgyODFjMzFhMTFiZDc0ZGE1MTM2OWQ2N2ViNzRhNzIyODdl");
                var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
                };
                fetch("https://gemnoble.com/wp-json/wc/v3/products/"+id+"?force=1", requestOptions)
                .then(response => response.text())
                .then(result => {
                    resolve(JSON.parse(result));
                    })
                .catch(error => reject(error));
        })
    }

    const handleDeleteToWeb = ()=>{
    
            setIsuploading(true);
            setStatus('Deleting ...');
            daleteToWeb(gem.woo.id)
            .then(data=>{
                setIsuploading(false);
                setStatus('');
                
                console.log(data);
                setListToWeb(false);
                handleUpdateWoo(null);
                Swal.fire({
                    icon: "success",
                    text: 'Deleted Successfully'
                    
                  })
                
                //setWebsiteURL(data.permalink);
                //setWooID(data.id);
                //setWoo(data);
            })
            .catch(error=>{
                setIsuploading(false);
                console.error(error);
                setStatus('error', error);
                //alert(error)
            
            })
            
        }


    const handleUpdateToWeb = ()=>{
    //console.log("Update ID : "+gem.woo.id)
        setIsuploading(true);
        setStatus('Updating to Web...');
        updateToWeb(gem,gem.woo.id)
        .then(data=>{
            setIsuploading(false);
            setStatus('');
            handleUpdateWoo(data);
            setWebsiteURL(data.permalink);
            setWooID(data.id);
            setWoo(data);
            Swal.fire({
                icon: "success",
                text: 'Updated Successfully'
                
              })
        })
        .catch(error=>{
            setIsuploading(false);
            console.error(error);
            setStatus('error', error);
            //alert(error)
        
        })
        
    }



    const handleListToWeb = ()=>{
            setIsuploading(true);
            setStatus('Listing to Web...');
            listToWeb(gem)
            .then(data => {
                setIsuploading(false);
                if(typeof data == 'object') {
                    console.log(data);
                    setWebsiteURL(data.permalink);
                    handleUpdateWoo(data);
                    setStatus('');
                    setListToWeb(true);
                    setWooID(data.id);
                    setWoo(data);
                    if(data.status === 'publish'){
                        setChecked(true);
                    }else if (data.status ==='private'){
                        setChecked(false);
                    }
                    Swal.fire({
                        icon: "success",
                        text: 'Listed Successfully'
                        
                      })
                    } 
                
            })
            .catch(error => {
                setIsuploading(false);
                console.log(error);
            })

    }

    

    const handleGetAccount = async()=>{
    
        
        //console.log(createXmlEbay(gem,accesstoken));
        
                let data = JSON.stringify({
                    "url": "https://api.ebay.com/ws/api.dll",
                    "headers": {
                      "X-EBAY-API-SITEID": "0",
                      "X-EBAY-API-COMPATIBILITY-LEVEL": "967",  
                      "X-EBAY-API-CALL-NAME": "GetAccount",
                      "Content-Type": "text/xml",
                      "X-EBAY-API-DETAIL-LEVEL":"0",
                      "X-EBAY-API-IAF-TOKEN": accesstoken
                    },
                    "body": escape(`<?xml version="1.0" encoding="utf-8"?>
                                    <GetAccountRequest xmlns="urn:ebay:apis:eBLBaseComponents">    
                                        <ErrorLanguage>en_US</ErrorLanguage>
                                        <WarningLevel>High</WarningLevel>
                                        <!-- This call does not work in the Sandbox environment -->
                                    <AccountEntrySortType>AccountEntryFeeTypeAscending</AccountEntrySortType>
                                    <AccountHistorySelection>LastInvoice</AccountHistorySelection>
                                    </GetAccountRequest>`)
                                });
                  
                  let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://app.gemnoble.com:2005/api/ebay/proxy',
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                  
                  axios.request(config)
                  .then((response) => {
                    const parser = new xml2js.Parser({
                        explicitArray: false, 
                        mergeAttrs: true,    
                    });
                    parser.parseString(response.data, (err, result) => {
                        if (err) {
                            console.error('Error parsing XML:', err);
                            return;
                        }
                        ///res.status(200).send(result);
                        //console.log(result.GetAccountResponse);
                        if(result.GetAccountResponse.Ack ==='Success'){
                        Swal.fire({
                            icon: "success",
                            title: "ACK : "+result.GetAccountResponse.Ack,
                            text: 'Account State : '+ result.GetAccountResponse.AccountSummary.AccountState,
                            footer: 'AccountID = '+result.GetAccountResponse.AccountID
                            
                          })} else if(result.GetAccountResponse.Ack ==='Failure') {
                            Swal.fire({
                                icon: "error",
                                title: "ACK : "+result.GetAccountResponse.Ack,
                                text: 'Error : '+ result.GetAccountResponse.Errors.LongMessage
                                
                              })

                          }
                    });
                    
                    
                    //console.log(response.data)
                    //console.log((response.data));
                  })
                  .catch((error) => {
                    //console.log(error);
                    Swal.fire({
                        icon: "error",
                        title: "Requst Error",
                        text: 'Error : '+ error
                        
                      })
                  });
                  
               
    }

    const handleListToEbay = async()=>{
    
        
        console.log(createXmlEbay(gem,accesstoken));
        
                let data = JSON.stringify({
                    "url": "https://api.ebay.com/ws/api.dll",
                    "headers": {
                      "X-EBAY-API-SITEID": "0",
                      "X-EBAY-API-COMPATIBILITY-LEVEL": "967",  
                      "X-EBAY-API-CALL-NAME": "AddItem",
                      "X-EBAY-API-IAF-TOKEN": accesstoken
                    },
                    "body": escape(createXmlEbay(gem,accesstoken)) });
                  
                  let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://app.gemnoble.com:2005/api/ebay/proxy',
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                  
                  axios.request(config)
                  .then((response) => {
                    const parser = new xml2js.Parser({
                        explicitArray: false, 
                        mergeAttrs: true,    
                    });
                    parser.parseString(response.data, (err, result) => {
                        if (err) {
                            console.error('Error parsing XML:', err);
                            return;
                        }
                        ///res.status(200).send(result);
                        console.log(result.AddItemResponse);
                        console.log(result.AddItemResponse.Ack);
                        if(result.AddItemResponse.Ack ==='Success'){
                            Swal.fire({
                                icon: "success",
                                title: "ACK : "+result.AddItemResponse.Ack,
                                text: 'Item ID : '+ result.AddItemResponse.ItemID
                            })
                        }else if(result.AddItemResponse.Ack ==='Failure'){
                            Swal.fire({
                                icon: "error",
                                title: "ACK : "+result.AddItemResponse.Ack,
                                text: 'Error : '+ result.AddItemResponse.Errors.LongMessage
                            })
                        }else if(result.AddItemResponse.Ack ==='Warning'){
                            Swal.fire({
                                icon: "warning",
                                title: "ACK : "+result.AddItemResponse.Ack,
                                text: 'Warning : '+ result.AddItemResponse.Warnings.LongMessage
                            })
                        }
                    });
                    //console.log(response.data)
                    //console.log((response.data));
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                  
               
    }

    
    const handleVisible = (event) => {
                setChecked(event.target.checked);
                if (checked==false){
                    setIsuploading(true);
                    setStatus('Updating to Web...');
                    updateVisible(gem,gem.woo.id,"publish")
                    .then(data=>{
                    setIsuploading(false);
                    setStatus('Updated Successfully');
                    handleUpdateWoo(data);
                    setWebsiteURL(data.permalink);
                    setWooID(data.id);
                    setWoo(data);
                })
                .catch(error=>{
                    setIsuploading(false);
                    console.error(error);
                    setStatus('error', error);
                    setChecked(true);
                
                })
                }else {
                    setIsuploading(true);
                    setStatus('Updating to Web...');
                    updateVisible(gem,gem.woo.id,"private")
                    .then(data=>{
                    setIsuploading(false);
                    setStatus('Updated Successfully');
                    handleUpdateWoo(data);
                    setWebsiteURL(data.permalink);
                    setWooID(data.id);
                    setWoo(data);
                })
                .catch(error=>{
                    setIsuploading(false);
                    console.error(error);
                    setStatus('error', error);
                    setChecked(false);
                
                })

                }
        
            };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpenImageDialog(true);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
        setSelectedImage(null);
    };

    return (

        <React.Fragment>
            <CssBaseline/>
            <Container maxWidth="sm" sx={{
                    p: 2
                }}>
                <Paper 
                    elevation={2} 
                    sx={{ 
                        p: 3, 
                        borderRadius: 2,
                        mb: 3
                    }}
                >
                    <Typography 
                        variant='h5' 
                        gutterBottom 
                        component='div'
                        sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                            color: 'primary.main',
                            mb: 3
                        }}
                    >
                        <EditIcon color="primary" />
                        Update Gem ID: {id}
                </Typography>

                    <Grid container spacing={3}>
                        {/* Basic Information Section */}
                        <Grid item xs={12}>
                            <Paper 
                                elevation={1} 
                                sx={{ 
                                    p: 2, 
                                    borderRadius: 2,
                                    backgroundColor: 'background.default'
                                }}
                            >
                                <Typography 
                                    variant='subtitle1' 
                                    sx={{ 
                                        mb: 2,
                                        color: 'text.secondary',
                                        fontWeight: 'medium'
                                    }}
                                >
                                    Basic Information
                                </Typography>
                                <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="weight"
                                value={weight}
                                type="number"
                                label="Gem Weight"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Ct</InputAdornment>
                                }}
                                fullWidth
                                required
                                disabled
                                            onChange={(e) => setWeight(e.target.value)}
                                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                            <InputLabel id="shape-label" required>Shape</InputLabel>
                                <Select
                                                labelId="shape-label"
                                    id="shape-select"
                                    value={shape}
                                                label="Shape"
                                    required
                                    disabled
                                                onChange={(e) => setShape(e.target.value)}
                                            >
                                                {shapeLists.map((shapelist) => (
                                                    <MenuItem key={shapelist.key} value={shapelist.key}>
                                                        {shapelist.value}
                                                    </MenuItem>
                                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Dimensions Section */}
                        <Grid item xs={12}>
                            <Paper 
                                elevation={1} 
                                sx={{ 
                                    p: 2, 
                                    borderRadius: 2,
                                    backgroundColor: 'background.default'
                                }}
                            >
                                <Typography 
                                    variant='subtitle1' 
                                    sx={{ 
                                        mb: 2,
                                        color: 'text.secondary',
                                        fontWeight: 'medium'
                                    }}
                                >
                                    Dimensions
                                </Typography>
                                <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="width"
                                value={width}
                                type="number"
                                label="Width"
                                variant="outlined"
                                disabled
                                InputProps={{
                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                }}
                                fullWidth
                                required
                                            onChange={(e) => setWidth(e.target.value)}
                                        />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="length"
                                value={length}
                                type="number"
                                label="Length"
                                variant="outlined"
                                disabled
                                InputProps={{
                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                }}
                                fullWidth
                                required
                                            onChange={(e) => setLength(e.target.value)}
                                        />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="depth"
                                value={depth}
                                type="number"
                                label="Depth"
                                variant="outlined"
                                disabled
                                InputProps={{
                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                }}
                                fullWidth
                                required
                                            onChange={(e) => setDepth(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Properties Section */}
                        <Grid item xs={12}>
                            <Paper 
                                elevation={1} 
                                sx={{ 
                                    p: 2, 
                                    borderRadius: 2,
                                    backgroundColor: 'background.default'
                                }}
                            >
                                <Typography 
                                    variant='subtitle1' 
                                    sx={{ 
                                        mb: 2,
                                        color: 'text.secondary',
                                        fontWeight: 'medium'
                                    }}
                                >
                                    Properties
                                </Typography>
                                <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                            <InputLabel id="color-label" required>Color</InputLabel>
                                <Select
                                                labelId="color-label"
                                    id="color-select"
                                    value={color}
                                    label="Color"
                                    required
                                    disabled
                                                onChange={(e) => setColor(e.target.value)}
                                            >
                                                {colorLists.map((colorlist) => (
                                                    <MenuItem key={colorlist.key} value={colorlist.key}>
                                                        {colorlist.value}
                                                    </MenuItem>
                                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                            <InputLabel id="clarity-label" required>Clarity</InputLabel>
                                <Select
                                                labelId="clarity-label"
                                    id="clarity-select"
                                    value={clarity}
                                    label="Clarity"
                                    required
                                    disabled
                                                onChange={(e) => setClarity(e.target.value)}
                                            >
                                                {clarityLists.map((claritylist) => (
                                                    <MenuItem key={claritylist.key} value={claritylist.key}>
                                                        {claritylist.value}
                                                    </MenuItem>
                                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                            <InputLabel id="origin-label" required>Origin</InputLabel>
                                <Select
                                                labelId="origin-label"
                                    id="origin-select"
                                    value={origin}
                                    label="Origin"
                                    required
                                    disabled
                                                onChange={(e) => setOrigin(e.target.value)}
                                            >
                                                {originLists.map((originlist) => (
                                                    <MenuItem key={originlist.key} value={originlist.key}>
                                                        {originlist.value}
                                                    </MenuItem>
                                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                            <InputLabel id="treatment-label" required>Treatment</InputLabel>
                                <Select
                                                labelId="treatment-label"
                                                id="treatment-select"
                                    value={treatment}
                                                label="Treatment"
                                    required
                                    disabled
                                                onChange={(e) => setTreatment(e.target.value)}
                                            >
                                                {treatmentLists.map((treatmentlist) => (
                                                    <MenuItem key={treatmentlist.key} value={treatmentlist.key}>
                                                        {treatmentlist.value}
                                                    </MenuItem>
                                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Certificate and Price Section */}
                        <Grid item xs={12}>
                            <Paper 
                                elevation={1} 
                                sx={{ 
                                    p: 2, 
                                    borderRadius: 2,
                                    backgroundColor: 'background.default'
                                }}
                            >
                                <Typography 
                                    variant='subtitle1' 
                                    sx={{ 
                                        mb: 2,
                                        color: 'text.secondary',
                                        fontWeight: 'medium'
                                    }}
                                >
                                    Certificate & Pricing
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                            <Select
                                            labelId="certificate-label"
                                id="certificate-multiple-checkbox"
                                multiple
                                fullWidth
                                disabled
                                            value={typeof certificate === 'string' ? certificate.split(',') : certificate}
                                onChange={(e) => {
                                                const {target: {value}} = e;
                                    setCertificate(
                                                    typeof value === 'string' ? value.split(',') : value
                                                );
                                            }}
                                            input={<OutlinedInput label="Certificate" />}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {certs.map((cert) => (
                                        <MenuItem key={cert} value={cert}>
                                            <Checkbox checked={certificate.indexOf(cert) > -1}/>
                                            <ListItemText primary={cert}/>
                                        </MenuItem>
                                            ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="price-input">Price</InputLabel>
                                <OutlinedInput
                                                id="price-input"
                                    type="number"
                                    value={price}
                                    required
                                    disabled
                                                onChange={e => setPrice(e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Price"
                                />
                            </FormControl>
                        </Grid>
                                    <Grid item xs={12}>
                        <FormControl>
                                            <FormLabel id="price-option-label">Price Option</FormLabel>
                        <RadioGroup
                                                aria-labelledby="price-option-label"
                                                name="price-option-group"
                            value={priceOption}
                            disabled
                            required
                        >
                                                <FormControlLabel 
                                                    disabled 
                                                    value="open" 
                                                    control={<Radio />} 
                                                    label="Open Price" 
                                                />
                                                <FormControlLabel 
                                                    disabled 
                                                    value="sale" 
                                                    control={<Radio />} 
                                                    label="Sale Price" 
                                                />
                        </RadioGroup>
                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* Rest of the sections remain unchanged */}
                        <Grid item xs={12}>
                            <Paper 
                                elevation={2} 
                                sx={{
                                    p: 2,
                                    borderRadius: 2,
                                    backgroundColor: 'background.default'
                                }}
                            >
                                <Typography 
                                    variant='h6' 
                                    gutterBottom 
                                    component='div'
                                    sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        gap: 1,
                                        color: 'primary.main',
                                        mb: 3
                                    }}
                                >
                                    <ImageIcon color="primary" />
                                    Images
                                </Typography>
                                <Box
                                    sx={{
                                        width: '100%',
                                        p: 2,
                                        border: '1px dashed',
                                        borderColor: 'divider',
                                        borderRadius: 2,
                                        backgroundColor: 'background.default'
                                    }}
                                >
                                    <ImageList
                                        sx={{
                                            width: '100%',
                                            height: isMobile ? 400 : 500,
                                            mb: 2
                                        }}
                                        cols={isMobile ? 2 : 3}
                                        gap={8}
                                        rowHeight={isMobile ? 150 : 164}
                                    >
                                        {imageDisplay.map((item, index) => (
                                            <ImageListItem 
                                                key={item.slug}
                                                sx={{
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                    borderRadius: 2,
                                                    overflow: 'hidden',
                                                    boxShadow: 1,
                                                    transition: 'all 0.3s ease',
                                                    '&:hover': {
                                                        transform: 'scale(1.02)',
                                                        boxShadow: 3,
                                                        '& .MuiImageListItem-img': {
                                                            opacity: 0.8,
                                                        },
                                                        '& .zoom-icon': {
                                                            opacity: 1,
                                                        },
                                                        '& .image-number': {
                                                            opacity: 1,
                                                        }
                                                    }
                                                }}
                                                onClick={() => handleImageClick(item)}
                                            >
                                                <img
                                                    src={item.media_details.sizes.woocommerce_thumbnail.source_url}
                                                    alt={`Gem view ${item.slug}`}
                                                    loading="lazy"
                                                    style={{
                                                        borderRadius: '8px',
                                                        transition: 'opacity 0.3s ease'
                                                    }}
                                                />
                                                <Box
                                                    className="image-number"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 8,
                                                        left: 8,
                                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                                        color: 'white',
                                                        borderRadius: '50%',
                                                        width: 24,
                                                        height: 24,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        fontSize: '0.75rem',
                                                        opacity: 0,
                                                        transition: 'opacity 0.3s ease'
                                                    }}
                                                >
                                                    {index + 1}
                                                </Box>
                                                <IconButton
                                                    className="zoom-icon"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        opacity: 0,
                                                        transition: 'opacity 0.3s ease',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                                        '&:hover': {
                                                            backgroundColor: 'white',
                                                        }
                                                    }}
                                                >
                                                    <ZoomInIcon />
                                                </IconButton>
                                            </ImageListItem>
                                        ))}
                                </ImageList>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant='h6' gutterBottom component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <PlayCircleIcon color="primary" />
                                    Videos
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: {
                                            xs: '1fr',
                                            sm: 'repeat(2, 1fr)',
                                            md: 'repeat(2, 1fr)'
                                        },
                                        gap: 2,
                                        width: '100%'
                                    }}
                                >
                                    {videoDisplay.map((item) => (
                                            <Card
                                            key={item.id}
                                                sx={{
                                                width: '100%',
                                                aspectRatio: '1',
                                                borderRadius: 2,
                                                overflow: 'hidden'
                                            }}
                                        >
                                                <CardCover>
                                                <video 
                                                    autoPlay 
                                                    loop 
                                                    muted 
                                                    playsInline
                                                    style={{ 
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                >
                                                        <source src={item.source_url} type="video/mp4"/>
                                                    </video>
                                                </CardCover>
                                            </Card>
                                    ))}
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant='h6' gutterBottom component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <DescriptionIcon color="primary" />
                                    Certificates
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: {
                                            xs: '1fr',
                                            sm: 'repeat(2, 1fr)',
                                            md: 'repeat(2, 1fr)'
                                        },
                                        gap: 2,
                                        width: '100%'
                                    }}
                                >
                                    {certDisplay.map((item) => (
                                            <Card
                                            key={item.id}
                                                sx={{
                                                width: '100%',
                                                aspectRatio: '1',
                                                borderRadius: 2,
                                                overflow: 'hidden',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    transform: 'scale(1.02)',
                                                    transition: 'transform 0.2s ease-in-out'
                                                }
                                            }}
                                            onClick={() => window.open(item.source_url, '_blank')}
                                        >
                                                <CardCover>
                                                <img 
                                                    src={item.source_url} 
                                                    alt="Certificate"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                                </CardCover>
                                            </Card>
                                    ))}
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant='h6' gutterBottom component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <EditIcon color="primary" />
                                    Create title and description
                                </Typography>
                                <Grid2 container spacing={2}>
                                    <Grid2 xs={12}>
                                    <TextField
                                    fullWidth 
                                            label="Title"
                                    multiline
                                    maxRows={4}
                                    value={title}
                                            onChange={e => setTitle(e.target.value)}
                                    disabled
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid2>
                                    <Grid2 xs={12}>
                                        <TextField
                                        fullWidth
                                        label="Description"
                                        multiline
                                        rows={4}
                                        value={description}
                                            onChange={e => setDescription(e.target.value)}
                                        disabled
                                        />
                                    </Grid2>
                                </Grid2>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant='h6' gutterBottom component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <ShoppingCartIcon color="primary" />
                                    Listing to WooCommerce
                                </Typography>
                                {isListToWeb ? (
                                    <Box>
                                        <Typography variant='body1' gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Woo Commerce ID: 
                                            <Link href={websiteURL} target="_blank" sx={{ textDecoration: 'none' }}>
                                                {wooid}
                                            </Link>
                                        </Typography>
                                        <Stack 
                                            direction={{ xs: 'column', sm: 'row' }} 
                                            spacing={2} 
                                            sx={{ mt: 2 }}
                                        >
                                                <Button
                                                onClick={handleUpdateToWeb}
                                                variant='contained'
                                                fullWidth
                                                startIcon={<EditIcon />}
                                                disabled={isUploading}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                color="error"
                                                onClick={handleDeleteToWeb}
                                                variant='contained'
                                                fullWidth
                                                disabled={isUploading}
                                            >
                                                Delete
                                            </Button>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={checked}
                                                     onChange={handleVisible}
                                                     disabled={isUploading}
                                                     />
                                                }
                                                label={checked ? "Published" : "Private"}
                                            />
                                            </Stack>
                                    </Box>
                                ) : (
                                    <Button
                                        onClick={handleListToWeb}
                                        variant='contained'
                                        fullWidth
                                        startIcon={<ShoppingCartIcon />}
                                        disabled={isUploading}
                                    >
                                        List to Website
                                    </Button>
                                )}
                                {(status || isUploading) && (
                                    <Box sx={{ mt: 2 }}>
                                        <Typography color="text.secondary" sx={{ mb: 1 }}>
                                        {status}
                                        </Typography>
                                        {isUploading && <LinearProgress />}
                                    </Box>
                                )}
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant='h6' gutterBottom component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <StoreIcon color="primary" />
                                    Listing to eBay
                                </Typography>
                                
                                <Box sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    gap: 2 
                                }}>
                                    {/* Token Status Section */}
                                    <Paper 
                                        variant="outlined" 
                                sx={{
                                    p: 2,
                                            backgroundColor: accesstoken_isValid ? 'success.light' : 'warning.light',
                                            color: accesstoken_isValid ? 'success.dark' : 'warning.dark',
                                            display: 'flex',
                                            flexDirection: isMobile ? 'column' : 'row',
                                            alignItems: isMobile ? 'stretch' : 'center',
                                            justifyContent: 'space-between',
                                            gap: 2
                                        }}
                                    >
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 0.5 }}>
                                                {accesstoken_isValid ? 'Token Status: Active' : 'Token Status: Inactive'}
                                </Typography>
                                            {accesstoken_isValid && (
                                                <Typography variant="body2">
                                                    Expires in: {Math.floor(accesstoken_expirein / 60)} minutes {accesstoken_expirein % 60} seconds
                                </Typography>
                                            )}
                                        </Box>
                                            <Button
                                            variant={accesstoken_isValid ? "outlined" : "contained"}
                                            href="https://app.gemnoble.com:2005/auth/ebay/"
                                            target="_blank"
                                            color={accesstoken_isValid ? "inherit" : "primary"}
                                            startIcon={<StoreIcon />}
                                            sx={{ 
                                                minWidth: isMobile ? '100%' : '200px',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            {accesstoken_isValid ? 'Refresh Token' : 'Obtain Token'}
                                        </Button>
                                    </Paper>

                                    {/* eBay Actions Section */}
                                    <Box sx={{ 
                                        display: 'flex', 
                                        flexDirection: isMobile ? 'column' : 'row',
                                        gap: 2 
                                    }}>
                                        <Button
                                            variant="contained"
                                            onClick={handleListToEbay}
                                            disabled={!accesstoken_isValid}
                                                    fullWidth
                                            startIcon={<StoreIcon />}
                                            sx={{
                                                flex: 1,
                                                height: 48
                                            }}
                                        >
                                            List to eBay
                                            </Button>
                                            <Button
                                            variant="outlined"
                                                    onClick={handleGetAccount}
                                            disabled={!accesstoken_isValid}
                                                    fullWidth
                                            startIcon={<AccountCircleIcon />}
                                            sx={{
                                                flex: 1,
                                                height: 48
                                            }}
                                        >
                                            Check Account Status
                                            </Button>
                                    </Box>

                                    {/* Loading Status */}
                                    {(status || isUploading) && (
                                        <Box sx={{ mt: 1 }}>
                                            <Typography color="text.secondary" sx={{ mb: 1 }}>
                                                {status}
                                            </Typography>
                                            {isUploading && (
                                                <LinearProgress 
                                                    sx={{ 
                                                        height: 6,
                                                        borderRadius: 1
                                                    }} 
                                                />
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        
      

                        <Grid item xs={12}>
                        {true?(<div></div>):(<Button
                                onClick={handleConsolelog}
                                variant='contained'
                                fullWidth
                                >CONSOLELOG</Button>)}
                            
                        </Grid>


                    </Grid>
                </Paper>
            </Container>
        </React.Fragment>
    );
}  