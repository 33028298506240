import React, {useState, useEffect} from 'react';
import {json, useParams} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {Button, Grid, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

import SaveIcon from '@mui/icons-material/Save';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {
    sellerLists,
    gemtypeLists,
    shapeLists,
    colorLists,
    clarityLists,
    originLists,
    treatmentLists,
    certs
} from '../utils/Utils';
import Swal from 'sweetalert2';
import {useNavigate} from "react-router-dom";
import { deleteMedia } from '../functions/wordpressAPI';
import { gemDataBaseUpdate } from '../functions/dataBaseAPI';
import {CreateTitle,CreateParagrahp,CreateWooDescription,createXmlEbay} from '../functions/functions';
import { getImagePlaceHolder } from '../functions/wordpressAPI';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid2 from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import Link from '@mui/material/Link';
import ImageIcon from '@mui/icons-material/Image';
import EditIcon from '@mui/icons-material/Edit';
import UploadFileIcon from '@mui/icons-material/UploadFile';


export default function GemsUpdate() {
    const naVigate = useNavigate();

    const [isUploading,setIsuploading] = useState(false);
    const [status,setStatus] = useState('');

    const {id} = useParams();

    useEffect(() => {
        getGem();
        

    }, [id])

    const getGem = () => {
        fetch("https://app.gemnoble.com:2005/gems/" + id, {
            method: 'GET',
            redirect: 'follow'
        })
            .then(
                response => response.json()
            )
            .then(result => {
                if (result['status'] === 'ok') {

                    //setId(result['gem']['id'])
                    setType(result['gem']['type'])
                    setWeight(result['gem']['weight'])
                    setShape(result['gem']['shape'])
                    setWidth(result['gem']['width'])
                    setLength(result['gem']['length'])
                    setDepth(result['gem']['depth'])
                    setColor(result['gem']['color'])
                    setClarity(result['gem']['clarity'])
                    setOrigin(result['gem']['origin'])
                    setTreatment(result['gem']['treatment'])
                    setCertificate(result['gem']['certificate'])
                    setSeller(result['gem']['seller'])
                    setImages(result['gem']['images'])
                    setVideos(result['gem']['videos'])
                    setCertimgs(result['gem']['certimgs'])
                    setPrice(result['gem']['price'])
                    setPriceOption(result['gem']['priceOption'])
                    setWoo(result['gem']['woo'])
                    setTitle(result['gem']['title'])
                    setDescription(result['gem']['description'])
                    

                    setImageDisplay(result['gem']['images'])
                    setVideoDisplay(result['gem']['videos'])
                    setCertDisplay(result['gem']['certimgs'])

                    

                }
            })
            .catch(error => console.log('error', error));
    }

    const isTitleMorethan80 = (title) => {
        if(title.length>80){
            return true
        }else{
            return false
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        if(isTitleMorethan80(title)){
            Swal.fire({
                icon: "error",
                title: "Title more than 80",
                text: 'Please edit title '
              })
            
        }else{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        setIsuploading(true);
        setStatus('Saving...')
        var raw = JSON.stringify({
            "id": id,
            "type": type,
            "weight": weight,
            "shape": shape,
            "width": width,
            "length": length,
            "depth": depth,
            "color": color,
            "clarity": clarity,
            "origin": origin,
            "treatment": treatment,
            "certificate": certificate,
            "seller": seller,
            "images": images,
            "videos": videos,
            "certimgs": certimgs,
            "price":price,
            "priceOption":priceOption,
            "woo":woo,
            "title":title,
            "description":description
            
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                if (result['status'] === 'ok') {
                    setIsuploading(false);
                    //setStatus("Item "+result['gem'].id+ " is Updated.");
                    setStatus("");
                    setIsUpdated(true);
                    Swal.fire({
                        icon: "success",
                        text: result.message
                        
                      }).then((value)=>{
                        naVigate('/listing/'+gem.id);
                        
                      });
                }
                
            })
            .catch(error => console.log('error', error));
        }

    }

    const handleUpdate = () => {
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "id": id,
            "type": type,
            "weight": weight,
            "shape": shape,
            "width": width,
            "length": length,
            "depth": depth,
            "color": color,
            "clarity": clarity,
            "origin": origin,
            "treatment": treatment,
            "certificate": certificate,
            "seller": seller,
            "images": images,
            "videos": videos,
            "certimgs": certimgs,
            "price":price,
            "priceOption":priceOption,
            "woo":woo,
            "title":title,
            "description":description
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
            .then(
                response => response.json()
            )
            .then(result => {
                alert(result['message'])
                if (result['status'] === 'ok') {
                    window.location.href = '/'
                }
            })
            .catch(error => console.log('error', error));

    }

    
    const [type, setType] = useState('');
    const [weight, setWeight] = useState('');
    const [shape, setShape] = useState('');
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [depth, setDepth] = useState('');
    const [color, setColor] = useState('');
    const [clarity, setClarity] = useState('');
    const [origin, setOrigin] = useState('');
    const [treatment, setTreatment] = useState('');
    const [certificate, setCertificate] = useState('');
    const [seller, setSeller] = useState('');
    const [images, setImages] = useState('');
    const [videos, setVideos] = useState('');
    const [certimgs, setCertimgs] = useState('');
    const [price, setPrice] = useState('');
    const [priceOption, setPriceOption] = useState('sale');
    const [woo, setWoo] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handlePriceOption = (event) => {
        setPriceOption(event.target.value);
      };
    

    const [isImagesPicked, setIsImagesPicked] = useState(false);
    const [isVideosPicked, setIsVideosPicked] = useState(false);
    const [isCertsPicked, setIsCertsPicked] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const [imageFilesSelect, setImageFilesSelect] = useState([]);
    const [imageDisplay, setImageDisplay] = useState([]);
    const [videoFilesSelect, setVideoFilesSelect] = useState([]);
    const [videoDisplay, setVideoDisplay] = useState([]);
    const [certimgFilesSelect, setCertimgFileSelect] = useState([]);
    const [certDisplay, setCertDisplay] = useState([]);

    const [imagesLoading, setImagesLoading] = useState(false);
    const [videosLoading, seVideosLoading] = useState(false);
    const [certsLoading, setCertsLoading] = useState(false);

    const [imagesLoadStatus, setImagesLoadStatus] = useState("");
    const [videosLoadStatus, seVideosLoadStatus] = useState("");
    const [certsLoadStatus, setCertsLoadStatus] = useState("");

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedImage, setSelectedImage] = useState(null);
    const [openImageDialog, setOpenImageDialog] = useState(false);

    const  gem = {
        "id":id,
        "seller": seller,
        "type": type,
        "weight": weight,
        "shape": shape,
        "width": width,
        "length": length,
        "depth": depth,
        "color": color,
        "clarity": clarity,
        "origin": origin,
        "treatment": treatment,
        "certificate": certificate,
        "images": images,
        "videos": videos,
        "certimgs": certimgs,
        "price":price,
        "priceOption":priceOption,
        "woo":woo,
        "title":title,
        "description":description
    };

    const selectImages = (e) => {
        
        setImageFilesSelect(Array.prototype.slice.call(e.target.files))
        if(e.target.files.length<=0){
            setIsImagesPicked(false);
        }else{
            setIsImagesPicked(true)
        }
    };

    const selectVideos = (e) => {
        setVideoFilesSelect(Array.prototype.slice.call(e.target.files))
        if(e.target.files.length<=0){
            setIsVideosPicked(false)
        }else{
            setIsVideosPicked(true)
        }
    };

    const selectCerts = (event) => {
        setCertimgFileSelect(Array.from(event.target.files));
        setIsCertsPicked(true);
    };



    const handleUploadImages = async () => {
        try {
            setImagesLoading(true);
            setImagesLoadStatus("Uploading images...");

            const uploadPromises = imageFilesSelect.map(async (file) => {
                const formData = new FormData();
                formData.append('file', file);
                
                const response = await fetch('https://gemnoble.com/wp-json/wp/v2/media', {
                method: 'POST',
                    headers: {
                        'Authorization': 'Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=='
                    },
                    body: formData
                });
                
                if (!response.ok) {
                    throw new Error('Upload failed');
                }
                
                const result = await response.json();
                return result;
            });

            const uploadedImages = await Promise.all(uploadPromises);
            const updatedImages = [...images, ...uploadedImages];

            // Update the gem in the database
            const updateResponse = await fetch("https://app.gemnoble.com:2005/gems/update", {
            method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...gem,
                    images: updatedImages
                })
            });

            if (!updateResponse.ok) {
                throw new Error('Failed to update gem');
            }

            setImages(updatedImages);
            setImageDisplay(updatedImages);
            setIsImagesPicked(false);
            setImageFilesSelect([]);
            setImagesLoadStatus("Images uploaded successfully!");
            
            Swal.fire({
                icon: "success",
                title: "Images Uploaded Successfully"
            });
        } catch (error) {
            console.error('Error:', error);
            setImagesLoadStatus("Error uploading images. Please try again.");
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to upload images"
            });
        } finally {
            setImagesLoading(false);
        }
    };

    const handleUploadVideos = async () => {
        try {
            seVideosLoading(true);
            seVideosLoadStatus("Uploading videos...");

            const uploadPromises = videoFilesSelect.map(async (file) => {
                const formData = new FormData();
                formData.append('file', file);
                
                const response = await fetch('https://gemnoble.com/wp-json/wp/v2/media', {
                method: 'POST',
                    headers: {
                        'Authorization': 'Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=='
                    },
                    body: formData
                });
                
                if (!response.ok) {
                    throw new Error('Upload failed');
                }
                
                const result = await response.json();
                return result;
            });

            const uploadedVideos = await Promise.all(uploadPromises);
            const updatedVideos = [...videos, ...uploadedVideos];

            // Update the gem in the database
            const updateResponse = await fetch("https://app.gemnoble.com:2005/gems/update", {
            method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...gem,
                    videos: updatedVideos
                })
            });

            if (!updateResponse.ok) {
                throw new Error('Failed to update gem');
            }

            setVideos(updatedVideos);
            setVideoDisplay(updatedVideos);
            setIsVideosPicked(false);
            setVideoFilesSelect([]);
            seVideosLoadStatus("Videos uploaded successfully!");
            
            Swal.fire({
                icon: "success",
                title: "Videos Uploaded Successfully"
            });
        } catch (error) {
            console.error('Error:', error);
            seVideosLoadStatus("Error uploading videos. Please try again.");
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to upload videos"
            });
        } finally {
            seVideosLoading(false);
        }
    };

    const handleUploadCerts = async () => {
        setCertsLoading(true);
        setCertsLoadStatus("Uploading certificates...");
        
        try {
            const uploadPromises = certimgFilesSelect.map(async (file) => {
                const formData = new FormData();
                formData.append('file', file);
                
                const response = await fetch('https://gemnoble.com/wp-json/wp/v2/media', {
                method: 'POST',
                    headers: {
                        'Authorization': 'Basic c29tcHJhc29uZzpwWGRoIGM1bmkgS0dJZiBPU0xBIEhiSkEgSDZPTg=='
                    },
                    body: formData
                });
                
                if (!response.ok) {
                    throw new Error('Upload failed');
                }
                
                const result = await response.json();
                return result;
            });

            const uploadedCerts = await Promise.all(uploadPromises);
            const updatedCertimgs = [...certimgs, ...uploadedCerts];

            // Update the gem in the database with new certificates
            const updateResponse = await fetch("https://app.gemnoble.com:2005/gems/update", {
            method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...gem,
                    certimgs: updatedCertimgs
                })
            });

            if (!updateResponse.ok) {
                throw new Error('Failed to update gem');
            }

            setCertimgs(updatedCertimgs);
            setCertDisplay(updatedCertimgs);
            setIsCertsPicked(false);
            setCertimgFileSelect([]);
            setCertsLoadStatus("Certificates uploaded successfully!");
        } catch (error) {
            console.error('Error:', error);
            setCertsLoadStatus("Error uploading certificates. Please try again.");
        } finally {
            setCertsLoading(false);
        }
    };

    const handleDeleteCert = async (certToDelete) => {
        try {
            // Delete from WordPress
            await deleteMedia(certToDelete.id);

            // Update local state
            const updatedCertimgs = certimgs.filter(cert => cert.id !== certToDelete.id);
            
            // Update the gem in the database
            const updateResponse = await fetch("https://app.gemnoble.com:2005/gems/update", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...gem,
                    certimgs: updatedCertimgs
                })
            });

            if (!updateResponse.ok) {
                throw new Error('Failed to update gem');
            }

            setCertimgs(updatedCertimgs);
            setCertDisplay(updatedCertimgs);
            setCertsLoadStatus("Certificate deleted successfully!");
        } catch (error) {
            console.error('Error:', error);
            setCertsLoadStatus("Error deleting certificate. Please try again.");
        }
    };

    const handleDeleteImages = async () => {
        const imageToDelete = images.pop().id
        
        if (images.length <1){
        alert("Unnable to delete");
        } else {
            setImagesLoading(true)
            setImagesLoadStatus("Deleting Image : "+imageToDelete )
            await deleteMedia(imageToDelete).then(result=>{
                if(JSON.parse(result).deleted==true){
                    var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    "id": id,
                    "type": type,
                    "weight": weight,
                    "shape": shape,
                    "width": width,
                    "length": length,
                    "depth": depth,
                    "color": color,
                    "clarity": clarity,
                    "origin": origin,
                    "treatment": treatment,
                    "certificate": certificate,
                    "seller": seller,
                    "images": images,
                    "videos": videos,
                    "certimgs": certimgs,
                    "price":price,
                    "priceOption":priceOption,
                    "woo":woo,
                    "title":title,
                    "description":description
                });
                var requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
                    .then(
                        response => response.json()
                    )
                    .then(result => {
                        //alert(result['message'])
                        if (result['status'] === 'ok') {
                            //window.location.href = '/update/'+id ;
                            var newimagesDisplay = images.slice(0,images.length);
                            //setImages(newimagesDisplay);
                            //setImageDisplay(newimagesDisplay);
                            console.log(result)
                            setImagesLoading(false)
                            setImagesLoadStatus(imageToDelete + "is Deleted!" )
                            getGem();
                            Swal.fire({
                                icon: "success",
                                title: imageToDelete + "is Deleted!"
                                
                              })
                            
                            
                        }
                    })
                    .catch(error => console.log('error', error));
                    
            
                }
                });
                
        }
    
    }

    const handleDeleteVideos = async () => {
        if (videos.length <=1){
        alert("Unnable to delete");
        } else {
            await deleteMedia(videos.pop().id).then(result=>{
                if(JSON.parse(result).deleted==true){
                    var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    "id": id,
                    "type": type,
                    "weight": weight,
                    "shape": shape,
                    "width": width,
                    "length": length,
                    "depth": depth,
                    "color": color,
                    "clarity": clarity,
                    "origin": origin,
                    "treatment": treatment,
                    "certificate": certificate,
                    "seller": seller,
                    "images": images,
                    "videos": videos,
                    "certimgs": certimgs,
                    "price":price,
                    "priceOption":priceOption,
                    "woo":woo,
                    "title":title,
                    "description":description
                });
                var requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                fetch("https://app.gemnoble.com:2005/gems/update/", requestOptions)
                    .then(
                        response => response.json()
                    )
                    .then(result => {
                        //alert(result['message'])
                        if (result['status'] === 'ok') {
                            window.location.href = '/update/'+id ;
                        }
                    })
                    .catch(error => console.log('error', error));
                    setVideos(videos)
                    setVideoDisplay(videos);
            
                }
                });
        }
        
    }

    const handleRaplaceImages = async () => {

    }

    const handleRaplaceVideos = async () => {

    }

    const handleRaplaceCerts = async () => {

    }

 

    const consoleLog = async () => {
       console.log(gem); 
        }

    const gemsListing = id =>{
        window.location = '/listing/' + id 
    }

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpenImageDialog(true);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
        setSelectedImage(null);
    };

    const handleDeleteImage = async (imageToDelete) => {
        try {
            if (images.length <= 1) {
                Swal.fire({
                    icon: "error",
                    title: "Cannot Delete",
                    text: "At least one image must remain"
                });
                return;
            }

            setImagesLoading(true);
            setImagesLoadStatus(`Deleting image: ${imageToDelete.id}`);

            // Delete from WordPress
            const deleteResult = await deleteMedia(imageToDelete.id);
            const parsedResult = JSON.parse(deleteResult);

            if (parsedResult.deleted) {
                // Update local state
                const updatedImages = images.filter(img => img.id !== imageToDelete.id);
                
                // Update the gem in the database
                const updateResponse = await fetch("https://app.gemnoble.com:2005/gems/update", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...gem,
                        images: updatedImages
                    })
                });

                if (!updateResponse.ok) {
                    throw new Error('Failed to update gem');
                }

                setImages(updatedImages);
                setImageDisplay(updatedImages);
                setImagesLoadStatus("Image deleted successfully!");
                
                Swal.fire({
                    icon: "success",
                    title: "Image Deleted Successfully"
                });
            }
        } catch (error) {
            console.error('Error:', error);
            setImagesLoadStatus("Error deleting image. Please try again.");
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to delete image"
            });
        } finally {
            setImagesLoading(false);
        }
    };

    const handleDeleteVideo = async (videoToDelete) => {
        try {
            seVideosLoading(true);
            seVideosLoadStatus(`Deleting video: ${videoToDelete.id}`);

            // Delete from WordPress
            const deleteResult = await deleteMedia(videoToDelete.id);
            const parsedResult = JSON.parse(deleteResult);

            if (parsedResult.deleted) {
                // Update local state
                const updatedVideos = videos.filter(video => video.id !== videoToDelete.id);
                
                // Update the gem in the database
                const updateResponse = await fetch("https://app.gemnoble.com:2005/gems/update", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...gem,
                        videos: updatedVideos
                    })
                });

                if (!updateResponse.ok) {
                    throw new Error('Failed to update gem');
                }

                setVideos(updatedVideos);
                setVideoDisplay(updatedVideos);
                seVideosLoadStatus("Video deleted successfully!");
                
                Swal.fire({
                    icon: "success",
                    title: "Video Deleted Successfully"
                });
            }
        } catch (error) {
            console.error('Error:', error);
            seVideosLoadStatus("Error deleting video. Please try again.");
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to delete video"
            });
        } finally {
            seVideosLoading(false);
        }
    };

    return (

        <React.Fragment>
            <CssBaseline/>
            <Container maxWidth="sm" sx={{
                    p: 2
                }}>
                <Typography variant='h6' gutterBottom component='div'>
                    Update Gem ID : {id}
                </Typography>
                
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>

                        
                        <Grid item xs={12} sm={6}><TextField
                                id="weight"
                                value={weight}
                                type="number"
                                label="Gem Weight"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Ct</InputAdornment>
                                }}
                                fullWidth={true}
                                required
                                onChange={(e) => setWeight(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Shape</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="shape-select"
                                    value={shape}
                                    label="Seller"
                                    required
                                    onChange={(e) => setShape(e.target.value)}>
                                    {
                                        shapeLists.map((shapelist) => (
                                            <MenuItem key={shapelist.key} value={shapelist.key}>{shapelist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="width"
                                value={width}
                                type="number"
                                label="Width"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth={true}
                                required
                                onChange={(e) => setWidth(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="length"
                                value={length}
                                type="number"
                                label="Length"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth={true}
                                required
                                onChange={(e) => setLength(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="depth"
                                value={depth}
                                type="number"
                                label="Depth"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mm.</InputAdornment>
                                }}
                                fullWidth={true}
                                required
                                onChange={(e) => setDepth(e.target.value)}/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Color</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="color-select"
                                    value={color}
                                    label="Color"
                                    required
                                    onChange={(e) => setColor(e.target.value)}>
                                    {
                                        colorLists.map((colorlist) => (
                                            <MenuItem key={colorlist.key} value={colorlist.key}>{colorlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Clarity</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="clarity-select"
                                    value={clarity}
                                    label="Clarity"
                                    required
                                    onChange={(e) => setClarity(e.target.value)}>
                                    {
                                        clarityLists.map((colorlist) => (
                                            <MenuItem key={colorlist.key} value={colorlist.key}>{colorlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Origin</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="origin-select"
                                    value={origin}
                                    label="Origin"
                                    required
                                    onChange={(e) => setOrigin(e.target.value)}>
                                    {
                                        originLists.map((originlist) => (
                                            <MenuItem key={originlist.key} value={originlist.key}>{originlist.value}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Treathment</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="treathment-select"
                                    value={treatment}
                                    label="Treathment"
                                    required
                                    onChange={(e) => setTreatment(e.target.value)}>
                                    {
                                        treatmentLists.map(
                                            (heatlist) => (<MenuItem key={heatlist.key} value={heatlist.key}>{heatlist.value}</MenuItem>)
                                        )
                                    }

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="certificate-multiple-checkbox"
                                label="Certificate"
                                multiple
                                fullWidth={true}
                                value={typeof certificate === 'string'
                                ? certificate.split(',')
                                : certificate}
                                onChange={(e) => {
                                    const {target: {
                                            value
                                        }} = e;
                                    setCertificate(
                                        // On autofill we get a stringified value.
                                            typeof value === 'string'
                                            ? value.split(',')
                                            : value,
                                    );
                                }}
                                input={<OutlinedInput label = "" />}
                                renderValue={(selected) => selected.join(', ')}>
                                {
                                    certs.map((cert) => (
                                        <MenuItem key={cert} value={cert}>
                                            <Checkbox checked={certificate.indexOf(cert) > -1}/>
                                            <ListItemText primary={cert}/>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={price}
                                    required
                                    onChange={e=>setPrice(e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Amount"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                        <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Price Option</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={priceOption}
                            onChange={handlePriceOption}
                            required
                        >
                            <FormControlLabel value="open" control={<Radio />} label="Open Price" />
                            <FormControlLabel value="sale" control={<Radio />} label="Sale Price" />
                            
                        </RadioGroup>
                        </FormControl>

                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant='h6' gutterBottom component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <ImageIcon color="primary" />
                                    Images
                                </Typography>

                                {/* Current Images Display */}
                                <Box
                                    sx={{
                                        width: '100%',
                                        p: 2,
                                        border: '1px dashed grey',
                                        borderRadius: 1,
                                        mb: 3
                                    }}
                                >
                                            <ImageList
                                                sx={{
                                            width: '100%',
                                            height: isMobile ? 400 : 500,
                                            mb: 2
                                        }}
                                        cols={isMobile ? 2 : 3}
                                        gap={8}
                                        rowHeight={isMobile ? 150 : 164}
                                    >
                                        {imageDisplay.map((item) => (
                                            <ImageListItem 
                                                key={item.slug}
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        '& .MuiImageListItem-img': {
                                                            opacity: 0.8,
                                                        },
                                                        '& .zoom-icon': {
                                                            opacity: 1,
                                                        },
                                                        '& .delete-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    position: 'relative'
                                                }}
                                            >
                                                <img
                                                    src={item.media_details.sizes.woocommerce_thumbnail.source_url}
                                                    alt={`Gem view ${item.slug}`}
                                                    loading="lazy"
                                                    style={{
                                                        borderRadius: '8px',
                                                        transition: 'opacity 0.3s ease'
                                                    }}
                                                    onClick={() => handleImageClick(item)}
                                                />
                                                <IconButton
                                                    className="zoom-icon"
                                                    onClick={() => handleImageClick(item)}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        opacity: 0,
                                                        transition: 'opacity 0.3s ease',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                                        }
                                                    }}
                                                >
                                                    <ZoomInIcon />
                                                </IconButton>
                                                <IconButton
                                                    className="delete-icon"
                                                    onClick={() => handleDeleteImage(item)}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 8,
                                                        right: 8,
                                                        opacity: 0,
                                                        transition: 'opacity 0.3s ease',
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                        color: 'white',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(255, 0, 0, 0.7)',
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ImageListItem>
                                        ))}
                                            </ImageList>
                                </Box>

                                {/* Upload Section */}
                                <Paper 
                                    variant="outlined" 
                                    sx={{ 
                                        p: 2, 
                                        borderStyle: 'dashed',
                                        mb: 2
                                    }}
                                >
                                    <Typography variant="subtitle1" gutterBottom>
                                        Add New Images
                                    </Typography>
                                    
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                component="label"
                                                variant="outlined"
                                                startIcon={<UploadFileIcon />}
                                                fullWidth
                                            >
                                                Select Images
                                <input
                                    type="file"
                                                    hidden
                                                    multiple
                                                    accept="image/*"
                                                    onChange={selectImages}
                                                />
                                            </Button>
                                    </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disabled={!isImagesPicked || imagesLoading}
                                                onClick={handleUploadImages}
                                                startIcon={<SendIcon />}
                                            >
                                                Upload
                                            </Button>
                                    </Grid>
                                    </Grid>

                                    {/* Selected Files Preview */}
                                    {isImagesPicked && (
                                        <Box sx={{ mt: 2 }}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                Selected Images:
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: {
                                                        xs: '1fr',
                                                        sm: 'repeat(2, 1fr)',
                                                        md: 'repeat(3, 1fr)'
                                                    },
                                                    gap: 2
                                                }}
                                            >
                                                {imageFilesSelect.map((file, index) => (
                                                    <Card
                                                        key={index}
                                                        sx={{
                                                            width: '100%',
                                                            aspectRatio: '1',
                                                            borderRadius: 2,
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        <CardCover>
                                                            <img
                                                                src={URL.createObjectURL(file)}
                                                                alt={`Selected image ${index + 1}`}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover'
                                                                }}
                                                            />
                                                        </CardCover>
                                                    </Card>
                                                ))}
                                    </Box>
                                    </Box>
                                    )}

                                    {/* Loading Status */}
                                    {(imagesLoading || imagesLoadStatus) && (
                                        <Box sx={{ mt: 2 }}>
                                            <Typography color="text.secondary" gutterBottom>
                                                {imagesLoadStatus}
                                            </Typography>
                                            {imagesLoading && <LinearProgress />}
                                        </Box>
                                    )}
                                </Paper>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant='h6' gutterBottom component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <PlayCircleIcon color="primary" />
                                    Videos
                                </Typography>

                                {/* Current Videos Display */}
                                <Box
                                    sx={{
                                        width: '100%',
                                        p: 2,
                                        border: '1px dashed grey',
                                        borderRadius: 1,
                                        mb: 3
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: {
                                                xs: '1fr',
                                                sm: 'repeat(2, 1fr)',
                                                md: 'repeat(2, 1fr)'
                                            },
                                        gap: 2,
                                            width: '100%'
                                        }}
                                    >
                                        {videoDisplay.map((item) => (
                                            <Card
                                                key={item.id}
                                                sx={{
                                                    width: '100%',
                                                    aspectRatio: '1',
                                                    borderRadius: 2,
                                                    overflow: 'hidden',
                                                    position: 'relative'
                                                }}
                                            >
                                                <CardCover>
                                                    <video 
                                                        autoPlay 
                                                        loop 
                                                        muted 
                                                        playsInline
                                                        style={{ 
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover'
                                                        }}
                                                    >
                                                        <source src={item.source_url} type="video/mp4"/>
                                                    </video>
                                                </CardCover>
                                                <IconButton
                                                    onClick={() => handleDeleteVideo(item)}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 8,
                                                        right: 8,
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                        color: 'white',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(255, 0, 0, 0.7)',
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Card>
                                        ))}
                                </Box>
                                </Box>

                                {/* Upload Section */}
                                <Paper 
                                    variant="outlined" 
                                    sx={{ 
                                        p: 2, 
                                        borderStyle: 'dashed',
                                        mb: 2
                                    }}
                                >
                                    <Typography variant="subtitle1" gutterBottom>
                                        Add New Videos
                                </Typography>
                                    
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                component="label"
                                                variant="outlined"
                                                startIcon={<UploadFileIcon />}
                                                fullWidth
                                            >
                                                Select Videos
                                <input
                                    type="file"
                                                    hidden
                                                    multiple
                                                    accept="video/*"
                                                    onChange={selectVideos}
                                                />
                                            </Button>
                                    </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disabled={!isVideosPicked || videosLoading}
                                                onClick={handleUploadVideos}
                                                startIcon={<SendIcon />}
                                            >
                                                Upload
                                            </Button>
                                    </Grid>
                                    </Grid>

                                    {/* Selected Files Preview */}
                                    {isVideosPicked && (
                                        <Box sx={{ mt: 2 }}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                Selected Videos:
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: {
                                                        xs: '1fr',
                                                        sm: 'repeat(2, 1fr)',
                                                        md: 'repeat(2, 1fr)'
                                                    },
                                                    gap: 2
                                                }}
                                            >
                                                {videoFilesSelect.map((file, index) => (
                                                        <Card
                                                        key={index}
                                                            sx={{
                                                            width: '100%',
                                                            aspectRatio: '1',
                                                            borderRadius: 2,
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                            <CardCover>
                                                            <video
                                                                autoPlay
                                                                loop
                                                                muted
                                                                playsInline
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover'
                                                                }}
                                                            >
                                                                <source src={URL.createObjectURL(file)} type="video/mp4"/>
                                                                </video>
                                                            </CardCover>
                                                        </Card>
                                                ))}
                                            </Box>
                                        </Box>
                                    )}

                                    {/* Loading Status */}
                                    {(videosLoading || videosLoadStatus) && (
                                        <Box sx={{ mt: 2 }}>
                                            <Typography color="text.secondary" gutterBottom>
                                                {videosLoadStatus}
                                            </Typography>
                                            {videosLoading && <LinearProgress />}
                                        </Box>
                                    )}
                                </Paper>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant='h6' gutterBottom component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <DescriptionIcon color="primary" />
                                    Certificates
                                </Typography>
                                
                                {/* Current Certificates Display */}
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: {
                                            xs: '1fr',
                                            sm: 'repeat(2, 1fr)',
                                            md: 'repeat(2, 1fr)'
                                        },
                                        gap: 2,
                                        width: '100%',
                                        mb: 3
                                    }}
                                >
                                    {certDisplay.map((item) => (
                                            <Card
                                            key={item.id}
                                                sx={{
                                                width: '100%',
                                                aspectRatio: '1',
                                                borderRadius: 2,
                                                overflow: 'hidden',
                                                position: 'relative'
                                            }}
                                        >
                                                <CardCover>
                                                <img 
                                                    src={item.source_url} 
                                                    alt="Certificate"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                                </CardCover>
                                            <IconButton
                                                onClick={() => handleDeleteCert(item)}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255, 0, 0, 0.7)',
                                                    }
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Card>
                                    ))}
                                </Box>

                                {/* Upload Section */}
                                <Paper 
                                    variant="outlined" 
                                    sx={{ 
                                        p: 2, 
                                        borderStyle: 'dashed',
                                        mb: 2
                                    }}
                                >
                                    <Typography variant="subtitle1" gutterBottom>
                                        Add New Certificates
                                </Typography>
                                    
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                component="label"
                                                variant="outlined"
                                                startIcon={<UploadFileIcon />}
                                                fullWidth
                                            >
                                                Select Files
                                                <input
                                                    type="file"
                                                    hidden
                                                    multiple
                                                    accept="image/*"
                                                    onChange={selectCerts}
                                                />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disabled={!isCertsPicked || certsLoading}
                                                onClick={handleUploadCerts}
                                                startIcon={<SendIcon />}
                                            >
                                                Upload
                                            </Button>
                                        </Grid>
                                </Grid>
                                
                                    {/* Selected Files Preview */}
                                    {isCertsPicked && (
                                        <Box sx={{ mt: 2 }}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                Selected Files:
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: {
                                                        xs: '1fr',
                                                        sm: 'repeat(2, 1fr)',
                                                        md: 'repeat(3, 1fr)'
                                                    },
                                                    gap: 2
                                                }}
                                            >
                                                {certimgFilesSelect.map((file, index) => (
                                                        <Card
                                                        key={index}
                                                            sx={{
                                                            width: '100%',
                                                            aspectRatio: '1',
                                                            borderRadius: 2,
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                            <CardCover>
                                                                <img
                                                                src={URL.createObjectURL(file)}
                                                                alt={`Selected certificate ${index + 1}`}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover'
                                                                }}
                                                            />
                                                            </CardCover>
                                                        </Card>
                                                ))}
                                            </Box>
                                        </Box>
                                    )}

                                    {/* Loading Status */}
                                    {(certsLoading || certsLoadStatus) && (
                                        <Box sx={{ mt: 2 }}>
                                            <Typography color="text.secondary" gutterBottom>
                                                {certsLoadStatus}
                            </Typography>
                                            {certsLoading && <LinearProgress />}
                        </Box>
                                    )}
                                </Paper>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ p: 2, borderRadius: 2 }}>
                                <Typography variant='h6' gutterBottom component='div' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <EditIcon color="primary" />
                                    Create title and description
                                </Typography>
                                <Grid2 container spacing={2}>
                                    <Grid2 xs={12}>
                                    <TextField
                                            fullWidth
                                    label="Title"
                                    multiline
                                    maxRows={4}
                                    value={title}
                                            onChange={e => setTitle(e.target.value)}
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid2>
                                    <Grid2 xs={12}>
                                        <TextField
                                            fullWidth
                                        label="Description"
                                        multiline
                                        rows={4}
                                        value={description}
                                            onChange={e => setDescription(e.target.value)}
                                        />
                                    </Grid2>
                                </Grid2>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            {
                                isUpdated?
                                (<div></div>):
                                (<Button type="submit" variant='contained' fullWidth={true} startIcon={<SaveIcon />}>Save</Button>)
                            
                            }


                            {true?(<div></div>):(<Button variant='contained' fullWidth={true} onClick={e=>{e.preventDefault();window.location.href = '/listing/'+gem.id;}}>Goto Listing</Button>)}

                            
                        </Grid>
                       

                       
                        <Grid item xs={12}>
                        {true?(<div></div>):(<Button
                                onClick={consoleLog}
                                variant='contained'
                                fullWidth={true}
                                

                                >CONSOLELOG</Button>)}
                            
                        </Grid>

                    </Grid>
                </form>
            </Container>
        </React.Fragment>
    );
}